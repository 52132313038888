import React from 'react'
import { EventRegistrationPage } from './_regPage'
import { regions } from '../../api/constants'
import { eventData } from '../../api/eventData'

export default function EventOnlyPageTemplate({
  pageContext: { id, title, subText, dateText },
}) {
  return (
    <EventRegistrationPage
      title={title}
      subtitle={subText}
      date={dateText}
      eventId={id}
      formIds={
        eventData[id]?.eventOnlyFormId || {
          US: '211116930194146',
          CA: '211116962112141',
          [regions.southAmerica]: '211168487459164',
        }
      }
      variant={'eventOnly'}
    />
  )
}
